<template>
  <v-row v-if="loading" align="center" justify="center" class="fill-height">
    <v-progress-circular
      :size="60"
      :width="5"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-row>

  <v-container v-else>
    <v-card max-width="547" class="mx-auto pt-6 pb-8 px-3 px-md-11">
      <v-form ref="form" v-model="valid">
        <attachment
          :file="businessLogo"
          @pushFileUp="uploadFile"
          @remove="removeLogo"
          @notValid="validImage = false"
          class="mb-6"
        />

        <v-text-field
          v-model="businessModel.businessName"
          :rules="businessNameRules"
          required
          :label="$t('panel.businessesPage.businessDetails.businessName')"
          :hint="$t('panel.businessesPage.businessDetails.businessNameHint')"
          persistent-hint
          counter="200"
          filled
          hide-details="auto"
          class="theme-custom-input mb-6"
        >
        </v-text-field>

        <v-text-field
          v-model="businessModel.businessType.businessTypeName"
          required
          :label="$t('panel.businessesPage.businessDetails.businessCategory')"
          filled
          hide-details="auto"
          class="theme-custom-input mb-6"
          disabled
        >
        </v-text-field>

        <div class="d-flex align-center mb-9">
          <span class="gray7--text me-1">
            {{ $t("panel.businessesPage.businessDetails.rate") }}
          </span>

          <v-tooltip
            v-model="showRateTooltip"
            top
            color="white"
            content-class="rate-tooltip"
            class="rate-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="16"
                color="transparent"
                class="me-6"
                v-bind="attrs"
                v-on="on"
                >$info
              </v-icon>
            </template>
            <span class="font-regular-12 gray8--text">
              {{ $t("panel.businessesPage.businessDetails.rateTooltipDesc") }}
            </span>
          </v-tooltip>

          <v-btn
            outlined
            color="primary"
            width="109"
            height="30"
            back
            class="rate-btn"
          >
            <v-icon size="16" class="me-1">$check</v-icon>
            <span class="font-medium-12">
              {{ $t("panel.businessesPage.businessDetails.defaultRate") }}
            </span>
          </v-btn>
        </div>

        <v-btn
          :disabled="!valid || !validImage"
          :loading="submitLoading"
          color="primary"
          block
          depressed
          height="50"
          @click="submit"
        >
          <span class="font-semiBold-14">
            {{ $t("panel.businessesPage.businessDetails.save") }}
          </span>
        </v-btn>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { MerchantService } from "@/services";
import Attachment from "../CreateBusiness/Attachment";

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = (event) => {
      resolve(event.srcElement.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export default {
  name: "business-details",
  props: ["id"],
  components: { Attachment },
  data() {
    return {
      loading: false,
      submitLoading: false,
      valid: false,
      showRateTooltip: false,
      validImage: true,
      businessModel: {
        id: "",
        logo: "",
        businessName: "",
        businessType: {},
      },
      businessLogo: "",
      rules: {
        required: (v) =>
          !!v ||
          this.$t("panel.businessesPage.businessDetails.businessNameNotEmpty"),
        max: (v) =>
          (v && v >= 200) ||
          this.$t("panel.businessesPage.businessDetails.businessNameLength"),
      },
      businessNameRules: [
        (v) =>
          !!v ||
          this.$t("panel.businessesPage.businessDetails.businessNameNotEmpty"),
        (v) =>
          (v && v.length <= 200) ||
          this.$t("panel.businessesPage.businessDetails.businessNameLength"),
      ],
    };
  },
  mounted() {
    this.getDetails();
    // if (this.businessModel.logo)
    //   this.businessModel.image = this.businessModel.logo;
  },
  methods: {
    getDetails() {
      this.loading = true;
      MerchantService.getMerchantById(this.id).then((res) => {
        this.businessModel = res.data.data;
        this.convertImage();
        this.loading = false;
      });
    },
    submit() {
      this.submitLoading = true;
      const data = {
        merchantId: this.businessModel.id,
        image: this.businessModel.image,
        businessName: this.businessModel.businessName,
      };
      MerchantService.updateMerchant(data)
        .then((res) => {
          this.$toast.success(res.data.message, {
            icon: this.$vuetify.icons.values.checkMarkDone,
          });
          this.$router.replace("/panel/businesses");
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    convertImage() {
      this.businessLogo = "data:image;base64," + this.businessModel.logo;
      delete this.businessModel.logo;
      // this.businessModel.logo = this.b64toBlob(this.businessLogo);
      // this.businessLogo = URL.createObjectURL(this.businessModel.logo);
    },
    b64toBlob(b64Data) {
      let arr = b64Data.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], "", { type: mime });
    },
    removeLogo() {
      this.businessLogo = "";
      this.businessModel.image = "";
    },
    uploadFile(file) {
      this.businessLogo = URL.createObjectURL(file);
      convertBase64(file).then((res) => {
        this.businessModel.image = res;
      });
      // this.businessModel.logo = file;
      this.validImage = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "BusinessDetails";
</style>
